import React from "react";
import { COLORS } from "../constants";
import { Text } from "./";
import styled from "styled-components";

interface Props {
  title: string;
  onClick: () => void;
}

const Container = styled.div`
  border-style: solid;
  border-width: 1px;
  border-color: ${COLORS.LIGHT};
  border-radius: 4px;
  padding: 0px 8px;
  width: auto;
  display: inline-block;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.LIGHT};
    transition: background-color 100ms;
  }
  &:hover p {
    color: ${COLORS.DARK} !important;
    transition: color 100ms;
  }
`;

export function TextButton({ title, onClick }: Props) {
  return (
    <Container role="button" className="text-button" onClick={onClick}>
      <Text>{title}</Text>
    </Container>
  );
}
