import React from "react";
import "./App.css";
import { Navigation } from "./Navigation";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        Haptic Interactions - Tic Tac Toe Survey
      </header>
      <main>
        <Navigation />
      </main>
    </div>
  );
}

export default App;
