import React from "react";
import { COLORS } from "../constants";

interface Props {
  type?: "normal" | "big" | "large" | "small" | "tiny";
}

export const Text: React.FC<Props> = ({ children, type }) => {
  const fontSize = (() => {
    switch (type) {
      case "big":
        return "1.25em";
      case "large":
        return "1.5em";
      case "small":
        return "0.8em";
      case "tiny":
        return "0.6em";
      default:
        return "1em";
    }
  })();
  const styles = {
    color: COLORS.LIGHT,
    fontSize,
    margin: "8px 0",
  };
  return <p style={styles}>{children}</p>;
};
