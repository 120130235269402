import React from "react";
import { TextButton, Text, ScreenWrapper } from "../components";
import { useNavigateToQuestion } from "../hooks";

export function Welcome() {
  const navigate = useNavigateToQuestion();
  return (
    <ScreenWrapper
      bottom={<TextButton title="Next ->" onClick={() => navigate(0)} />}
    >
      <Text type="big">Hello!</Text>
      <Text>
        Welcome to my survey conducted for university project at Tampere
        University.
      </Text>
      <Text>
        In the following slides you will be presented with 5 different
        vibrations and you will need to select one of the symbols that it
        reminds you of.
      </Text>
      <Text>You can select one symbol multiple times!</Text>
      <Text>Thank you and have fun</Text>
    </ScreenWrapper>
  );
}
