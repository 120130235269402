import React from "react";
import { useSearchParams } from "react-router-dom";
import { Question } from "./Question";
import { Welcome } from "./Welcome";

export function Screen() {
  const [params] = useSearchParams();
  const type = params.get("type");
  switch (type) {
    case "question":
      return <Question />;
    default:
      return <Welcome />;
  }
}
