import React from "react";
import { useSearchParams } from "react-router-dom";
import { TextButton, Text, ScreenWrapper } from "../components";
import { useNavigateToQuestion } from "../hooks";
import { vibrate } from "../utils";

export function Question() {
  const navigate = useNavigateToQuestion();
  const [params] = useSearchParams();
  const indexParam = params.get("index");
  const index = indexParam ? Number(indexParam) : 0;
  const handlePlay = () => {
    try {
      vibrate([200, 100, 200]);
    } catch (e: any) {
      alert(e.message ?? e);
    }
  };
  return (
    <ScreenWrapper
      bottom={
        <TextButton title="Next ->" onClick={() => navigate(index + 1)} />
      }
    >
      <Text type="big">Question {index}!</Text>
      <Text>What is the most suitable symbol for that vibration?</Text>
      <TextButton title="Play vibration" onClick={handlePlay} />
    </ScreenWrapper>
  );
}
