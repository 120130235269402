import React from "react";

interface Props {
  bottom: React.ReactNode;
}

export const ScreenWrapper: React.FC<Props> = ({ children, bottom }) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <div>{children}</div>
      {bottom}
    </div>
  );
};
